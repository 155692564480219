<template>
	<div id="small-navbar">
		<div class="container">
			<div
				class="row navbar-row d-flex align-items-center justify-content-between"
			>
				<div class="col-6">
					<div class="navbar-logo">
						<router-link :to="{ name: 'HomeScreen' }"
							><img class="navbar-logo" src="/images/logos/bb-logo-text.svg"
						/></router-link>
					</div>
				</div>
				<div class="col-6 d-flex justify-content-end">
					<div class="toggle-menu" @click="toggleMenu">
						<i class="bb-menu"></i>
					</div>
				</div>
			</div>
		</div>
		<transition name="slide-fade-bot">
			<mobile-navbar-menu
				v-if="mobileMenuIsOpen"
				:languageOptions="languageOptions"
				:navigations="navigations"
				:defaultLanguage="defaultLanguage"
				@closeMenu="closeMenu"
			/>
		</transition>
	</div>
</template>

<script>
import MobileNavbarMenu from './MobileNavbarMenu';
export default {
	name: 'SmallNavbar',
	props: {
		defaultLanguage: {
			type: Object,
			required: false,
		},
		languageOptions: {
			type: Array,
			required: false,
		},
		navigations: {
			type: Array,
			required: true,
		},
	},
	components: {
		MobileNavbarMenu,
	},
	data: () => ({
		mobileMenuIsOpen: false,
	}),
	created() {},
	computed: {},
	methods: {
		closeMenu() {
			this.mobileMenuIsOpen = false;
		},
		toggleMenu() {
			this.mobileMenuIsOpen = !this.mobileMenuIsOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
#small-navbar {
	background: #e8e8ff;
	$navbarHeight: 79px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
	.container {
		@media (max-width: $breakpoint-sm) {
			max-width: 100%;
		}
	}
	.navbar-row {
		min-height: $navbarHeight;
		.navbar-logo {
			max-height: 37px;
		}

		.navigation {
			display: flex;
			align-items: center;
			list-style-type: none;
			margin: 0;
			padding: 0;
			position: relative;
			height: $navbarHeight;
		}
		.toggle-menu {
			height: 45px;
			i {
				font-size: 45px;
				cursor: pointer;
			}
		}
	}
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
