var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"footer"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row first-row"},[_vm._m(0),_c('div',{staticClass:"col-lg-2 col-3"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('footer.company')))]),_c('router-link',{staticClass:"link",attrs:{"to":"/about-us"}},[_vm._v(_vm._s(_vm.$t('globals.navigation.aboutUs')))]),_c('a',{staticClass:"link",attrs:{"href":"https://blog.blockben.com/"}},[_vm._v(_vm._s(_vm.$t('globals.navigation.blog')))])],1),_c('div',{staticClass:"col-lg-3 col-8"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('footer.help')))]),_c('a',{staticClass:"link",attrs:{"href":"mailto:support@blockben.com"}},[_vm._v(_vm._s(_vm.$t('globals.navigation.contact')))])]),_c('div',{staticClass:"divider",staticStyle:{"margin-top":"50px","margin-left":"15px","margin-right":"15px"}}),_c('div',{staticClass:"col-lg-2 col-12"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('footer.follow')))]),_vm._m(1)])]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"row second-row"},[_c('div',{staticClass:"col-lg-6 d-none d-lg-block",staticStyle:{"padding-left":"0px"}},[_c('div',{staticClass:"legal-notice"},[_vm._v(" "+_vm._s(_vm.$t('footer.legalNotice'))+" ")])]),_c('div',{staticClass:"col-lg-6 d-none d-lg-block d-xl-block",staticStyle:{"padding-right":"0px"}},[_c('div',{staticClass:"legal-links"},[_c('p',{staticClass:"legal-link"},[_c('router-link',{attrs:{"to":"/legal-notice"}},[_vm._v(_vm._s(_vm.$t('footer.legal.disclaimer')))])],1),_c('p',{staticClass:"legal-link"},[_c('a',{attrs:{"href":`${
								_vm.$i18n.locale === 'hu'
									? 'https://data.blockben.com/terms/A_BlockWallet_GTC_hu.pdf'
									: 'https://data.blockben.com/terms/A_BlockWallet_GTC_en.pdf'
							}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.generalTermsAndConditions'))+" ")])]),_c('p',{staticClass:"legal-link"},[_c('a',{attrs:{"href":`${
								_vm.$i18n.locale === 'hu'
									? 'https://data.blockben.com/gdpr/Privacy_Policy_hu.pdf'
									: 'https://data.blockben.com/gdpr/Privacy_Policy_en.pdf'
							}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.dataProtection'))+" ")])]),_c('p',{staticClass:"legal-link"},[_c('a',{attrs:{"href":`${
								_vm.$i18n.locale === 'hu'
									? 'https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_en.pdf'
									: 'https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_en.pdf'
							}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.aml'))+" ")])]),_c('p',{staticClass:"legal-link cookie-settings-btn",on:{"click":_vm.openCookieSettingsModal}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.cookieSettings'))+" ")])])]),_c('div',{staticClass:"col-12 d-lg-none"},[_c('router-link',{staticClass:"legal-link-xs",attrs:{"to":"/legal-notice"}},[_vm._v(_vm._s(_vm.$t('footer.legal.disclaimer')))]),_c('a',{staticClass:"legal-link-xs margin-top-s",attrs:{"href":`https://data.blockben.com/terms/A_BlockWallet_GTC_${_vm.$i18n.locale}.pdf`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.generalTermsAndConditions'))+" ")]),_c('a',{staticClass:"legal-link-xs margin-top-s",attrs:{"href":`https://data.blockben.com/gdpr/Privacy_Policy_${_vm.$i18n.locale}.pdf`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.dataProtection'))+" ")]),_c('a',{staticClass:"legal-link-xs margin-top-s",attrs:{"href":`https://data.blockben.com/terms/F_Anti_Money_Laundering_extract_${_vm.$i18n.locale}.pdf`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.aml'))+" ")]),_c('p',{staticClass:"legal-link-xs margin-top-s",on:{"click":_vm.openCookieSettingsModal}},[_vm._v(" "+_vm._s(_vm.$t('footer.legal.cookieSettings'))+" ")])],1),_c('div',{staticClass:"divider",staticStyle:{"margin-top":"50px","margin-left":"15px","margin-right":"15px"}}),_c('div',{staticClass:"col-12 d-lg-none"},[_c('div',{staticClass:"legal-notice-xs"},[_vm._v(_vm._s(_vm.$t('footer.legalNotice')))])]),_c('div',{staticClass:"divider",staticStyle:{"margin-top":"30px","margin-left":"15px","margin-right":"15px"}})]),_c('div',{staticClass:"row third-row"},[_c('div',{staticClass:"company-info"},[_vm._v(_vm._s(_vm.$t('footer.companyInfo')))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-5 col-12 icon-col"},[_c('img',{staticClass:"blockben-icon",attrs:{"width":"167px","src":"/images/logos/bb-logo-text-white.svg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icons"},[_c('a',{attrs:{"href":"https://www.facebook.com/blockbenofficial"}},[_c('i',{staticClass:"bb-facebook icon"})]),_c('a',{attrs:{"href":"https://twitter.com/blockben?lang=en"}},[_c('img',{staticClass:"icon",attrs:{"src":"/images/icons/x.svg"}})]),_c('a',{attrs:{"href":"https://www.linkedin.com/company/blockben/"}},[_c('i',{staticClass:"bb-linkedin icon"})]),_c('a',{attrs:{"href":"https://www.youtube.com/channel/UC9SB-B1Gf50SVnerTLGIMyA"}},[_c('i',{staticClass:"bb-youtube icon"})])])
}]

export { render, staticRenderFns }