<template>
	<div id="large-navbar">
		<div class="container">
			<div class="row navbar-row-top d-flex align-items-center">
				<div class="col-3 navbar-logo">
					<router-link to="/"
						><img class="navbar-logo" src="/images/logos/bb-logo-text.svg"
					/></router-link>
				</div>
				<div class="col-lg-9 col-md-9">
					<div
						class="buttons-row w-100 d-flex align-items-center justify-content-end"
					>
						<dropdown-button
							class="dropdown"
							icon="bb-sphere"
							:text="'Button Text'"
							type="secondary"
							size="small"
							selectText="shortLabel"
							optionText="label"
							selectValue="value"
							:options="languageOptions"
							:value="selectedValue"
							@input="languageSelected"
						></dropdown-button>
						<base-button
							class="registration"
							:type="'secondary'"
							:text="$t('globals.navigation.profile.login')"
							@clicked="handleNavbarBtnClick('login')"
						/>
						<base-button
							class="login"
							:text="$t('globals.navigation.profile.registration')"
							@clicked="handleNavbarBtnClick('registration')"
						/>
					</div>
				</div>
			</div>
			<div class="row navbar-row-bottom d-flex align-items-center">
				<div class="col-lg-12 col-md-12">
					<ul class="navigation">
						<template v-for="(item, i) in navigations">
							<li v-if="!item.subItems" :key="i" class="navigation-item">
								<router-link
									v-if="!item.link.includes('https')"
									:to="item.link"
									>{{ $t(item.titleKey) }}</router-link
								>
								<a v-else :href="item.link" target="_blank">{{
									$t(item.titleKey)
								}}</a>
							</li>
							<li v-else :key="i" class="navigation-item has-child">
								<span class="parent"
									>{{ $t(item.titleKey) }} <i class="bb-arrow-down"></i
								></span>
								<navbar-dropdown :items="item.subItems" />
							</li>
						</template>
						<span
							class="navigation-indicator"
							:style="`left: ${indicatorXPos}px; width: ${indicatorWidth}px`"
						/>
					</ul>
				</div>
			</div>
		</div>
		<div class="exchange-row">
			<exchange-row />
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ExchangeRow from './ExchangeRow';

const NavbarDropdown = () => import('@/components/elements/NavbarDropdown');

export default {
	name: 'LargeNavbar',
	props: {
		navigations: {
			type: Array,
			required: true,
		},
		defaultLanguage: {
			type: Object,
			required: false,
		},
		languageOptions: {
			type: Array,
			required: false,
		},
		navbarHeight: {
			type: String,
			default: '92px',
		},
	},
	components: {
		ExchangeRow,
		NavbarDropdown,
	},
	data: () => ({
		indicatorXPos: 0,
		indicatorWidth: 0,
		selectedValue: undefined,
	}),
	created() {},
	computed: {
		...mapGetters({
			getSelectedLanguage: 'settings/getSelectedLanguage',
		}),
	},
	watch: {
		defaultLanguage: {
			immediate: true,
			handler() {
				this.selectedValue = this.defaultLanguage;
			},
		},
		$route(to, from) {
			setTimeout(() => {
				this.setIndicatorPosition(to.path);
			});
		},
		getSelectedLanguage: {
			immediate: true,
			handler() {
				setTimeout(() => {
					this.setIndicatorPosition(this.$route.path);
				});
			},
		},
	},
	methods: {
		...mapActions({
			setSelectedLanguage: 'settings/setSelectedLanguage',
		}),
		languageSelected(e) {
			this.selectedValue = e;
			this.setSelectedLanguage(this.selectedValue);
			this.$router.push({
				name: this.$route.name,
				params: { lang: this.selectedValue },
			});
		},
		handleNavbarBtnClick(type) {
			if (type === 'login') {
				window.open('https://wallet.blockben.com/login', '_blank');
			} else if (type === 'registration') {
				window.open('https://wallet.blockben.com/register', '_blank');
			}
		},
		setIndicatorPosition(route) {
			let parsedRoute = route.split('/');
			parsedRoute = parsedRoute[parsedRoute.length - 1];

			let activeItem = document.querySelector(
				`.navigation-item a[href*="${parsedRoute}"]`,
			);

			if (route.split('/').includes('products')) {
				activeItem = document.querySelector(
					'.navigation-item.has-child .parent',
				);

				// this.indicatorWidth = activeItem ? activeItem.offsetWidth + 15 : 0;
				// this.indicatorXPos = activeItem ? activeItem.offsetLeft - 15 : 0;
				this.indicatorWidth = activeItem ? activeItem.offsetWidth : 0;
				this.indicatorXPos = activeItem ? activeItem.offsetLeft : 0;
			} else {
				this.indicatorWidth = activeItem ? activeItem.offsetWidth : 0;
				this.indicatorXPos = activeItem ? activeItem.offsetLeft : 0;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
	1% {
		transform: scale(1);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

#large-navbar {
	$desktopHeight: 92px;
	$desktopNavigationHeight: 66px;
	background: #e8e8ff;

	.container {
		max-width: 1196px !important;
	}

	.navbar-row-top {
		min-height: $desktopHeight;
	}
	.navbar-row-bottom {
		min-height: $desktopNavigationHeight;
	}

	.navbar-logo {
		max-height: 38px;
	}

	.registration {
		background: #e8e8ff;
	}

	::v-deep .secondary {
		background: #e8e8ff;
	}
	::v-deep .default.open {
		background: $primary-purple-dark;
	}

	.navigation {
		display: flex;
		align-items: center;
		list-style-type: none;
		margin: 0;
		padding: 0;
		position: relative;
		height: $desktopNavigationHeight;
		justify-content: space-between;

		.navigation-indicator {
			display: inline-block;
			width: 67px;
			height: 8px;
			background-color: $black;
			position: absolute;
			bottom: 0px;
			transition: $transition-global;
		}

		.navigation-item {
			a {
				font-size: 13px;
				font-family: 'HKNova', sans-serif;

				&:hover {
					text-decoration: none;
					color: #4656ff;
				}
			}

			&.has-child {
				position: relative;

				&:hover {
					.navbar-dropdown {
						animation: fadeIn 0.3s ease-out forwards;
					}

					.parent {
						color: $link-hover-color;
					}
				}

				.navbar-dropdown {
					position: absolute;
					top: 100%;
					left: -15px;
					transform: scale(0);
					opacity: 0;
					padding: 34px 0 0;
					z-index: 10;
				}

				.parent {
					font-size: 14px;
					font-weight: 700;
					color: $primary-purple;
					cursor: pointer;
					transition: $transition-global;
					display: flex;
					align-items: center;

					i {
						font-weight: 700;
						font-size: 8px;
						padding: 2px 0 0 5px;
					}
				}
			}
		}
	}

	.buttons-row {
		gap: 15px;
	}
}

@media (max-width: 1200px) {
	.registration,
	.login {
		display: none;
	}
}

@media (max-width: 880px) {
	.navigation {
		// max-width: 660px;
	}
}
</style>
