<template>
	<button :class="['default', size, type]" @click="handleClick">
		{{ text }}
	</button>
</template>

<script>
export default {
	name: 'Button',
	props: {
		size: {
			type: String,
			default: 'small',
			validator: (val) => ['large', 'small'].includes(val),
		},
		type: {
			type: String,
			default: 'primary',
			validator: (val) =>
				[
					'primary',
					'secondary',
					'dark-blue',
					'yellow',
					'blockstock-yellow',
					'light-green',
					'black',
					'light-purple',
				].includes(val),
		},
		text: {
			type: String,
			required: true,
		},
	},
	components: {},
	data: () => ({}),
	created() {},
	computed: {},
	methods: {
		handleClick() {
			this.$emit('clicked');
		},
	},
};
</script>

<style lang='scss' scoped>
button {
	cursor: pointer;
}

.default {
	border: none;
	border-radius: 10px;
	font-size: $text-m;
}
.large {
	height: 60px;
	padding: 0px 30px;
}

.small {
	height: 40px;
	padding: 0px 20px;
}

.primary {
	color: $white;
	background: $black;

	&:hover {
		// background: linear-gradient($black, $primary-purple);
		background: #4656ff;
	}

	&.large {
		box-shadow: 0px 10px 20px -10px rgba(71, 50, 165, 0.2),
			0px 20px 30px -10px rgba(71, 50, 165, 0.15);
	}
}

.light-purple {
	color: $text-color;
	background: $light-purple;
	&:hover {
		background: linear-gradient($light-purple, #351993);
	}
}

.dark-blue {
	color: $white;
	background: $dark-blue;

	&:hover {
		background: linear-gradient($dark-blue, #361d7e);
	}
}

.yellow {
	color: $black;
	background: $yellow;

	&:hover {
		background: linear-gradient($yellow, #d9af55);
	}
}

.blockstock-yellow {
	color: $black;
	background: $blockstock-yellow;

	&:hover {
		background: linear-gradient(
			$blockstock-yellow,
			$blockstock-gradient-yellow
		);
	}
}

.light-green {
	color: $black;
	background: $light-green;

	&:hover {
		background: linear-gradient($light-green, #0fceab);
	}
}

.black {
	color: $white;
	background: $dark-grey;

	&:hover {
		background: linear-gradient(#3e3e3e, $dark-grey);
	}
}

.secondary {
	color: $dark-blue;
	background: $white;
	border: 1px solid $light-purple;

	&:hover {
		background: rgba(246, 244, 255, 0.5);
	}
}
</style>
