<template>
	<div id="exchange-row">
		<div v-if="exchanges" class="container-fluid h-100 px-0">
			<carousel
				ref="carousel"
				:loop="true"
				:autoplay="true"
				:margin="75"
				:autoplayTimeout="4500"
				:autoplaySpeed="4500"
				:nav="false"
				:dots="false"
				:items="6"
				:rewind="false"
				:autoplayHoverPause="false"
				:slideTransition="'linear'"
				:touchDrag="false"
				:responsive="carouselConfig"
			>
				<div
					class="exchange-element"
					v-for="(item, index) in exchanges"
					:key="index"
				>
					<div class="exchange">
						<span class="asset-name"
							>{{ setAssetUnit(item.asset) }}
							{{ setAssetName(item.asset) }}</span
						>
						<span class="asset-price"
							>{{ $t('exchangeRow.pricePrefix')
							}}{{ setCurrentPrice(item.asset, item.currentPrice) }}</span
						>
						<span
							class="delta"
							:class="underZero(item.delta) ? 'negative' : 'positive'"
						>
							({{ item.delta }}%)
							<span v-if="item.delta != 0" class="asset-icon" />
						</span>
					</div>
				</div>
			</carousel>
		</div>
	</div>
</template>

<script>
import Api from '@/api';
import carousel from 'vue-owl-carousel';

export default {
	name: 'ExchangeRow',
	props: {},
	components: {
		carousel,
	},
	data: () => ({
		exchanges: undefined,
		carouselConfig: {
			0: {
				items: 4,
			},
			992: {
				items: 6,
			},
			1400: {
				items: 8,
			},
		},
	}),
	created() {},
	computed: {},
	methods: {
		underZero(number) {
			return parseFloat(number) < 0;
		},
		setCurrentPrice(assetName, price) {
			return parseFloat(price).toFixed(2);
		},
		setAssetUnit(name) {
			switch (name) {
				case 'BSO':
					return '1k';
				case 'BNO':
					return '100';
				case 'BNOx':
					return '100';
				default:
					return '';
			}
		},
		setAssetName(name) {
			switch (name) {
				case 'BSO':
					return 'eBSO';
				default:
					return name;
			}
		},
	},
	async mounted() {
		let response = await Api.get(`priceStrip`);
		this.exchanges = await response.json();
		this.exchanges = this.exchanges.filter((item) =>
			this.$helpers.getWhitelistedAssets().includes(item.asset),
		);
		// !!!
		if (this.$helpers.getWhitelistedAssets().includes('BNO')) {
			this.exchanges.push({
				asset: 'BNOx',
				currentPrice: (
					this.exchanges.find((obj) => obj.asset == 'BNO').currentPrice * 1.005
				).toFixed(2),
				delta: this.exchanges.find((obj) => obj.asset == 'BNO').delta,
			});
		}
	},
};
</script>

<style lang="scss" scoped>
#exchange-row {
	width: 100vw;
	height: 28px;
	background: $black;

	&::v-deep {
		.container-fluid > div {
			height: 100%;
		}

		.owl-carousel,
		.owl-stage-outer {
			height: 100%;
		}

		.owl-stage {
			height: 100%;
			display: flex;
			align-items: center;
		}
	}

	.exchange-element {
		.exchange {
			display: flex;
			white-space: pre;
			color: $lighter-purple;
			font-size: 11px;
			line-height: 14px;

			.delta {
				display: flex;
				align-items: center;

				&.negative {
					color: #fe6161;
				}

				&.positive {
					color: #2de4d5;
				}
			}
		}
	}
}

.asset-name {
	padding-right: 5px;
}

.asset-icon {
	display: inline-block;
	mask-image: url('/images/icons/bb-icon_arrow-triangle.svg');
	mask-repeat: no-repeat;
	mask-size: contain;
	width: 9px;
	height: 6px;

	.positive & {
		background-color: $light-green;
		transform: rotate(180deg);
	}

	.negative & {
		background-color: $light-red;
	}
}
</style>
